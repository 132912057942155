import { Grid } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Colors } from "../../../../../../../constants";
import TransferListEditUsage from "./usage-edit-transfer-list";
import { BookDTO, CadastralBasicPropertyDTO, UsageDTO } from "../../../../../../../clients";
import { ConfirmDialogStrings } from "@movicoders/movicoders-components";
import { Button } from "@movicoders/movicoders-components";
import ReportViewModel from "../../../../../../../viewmodels/report/report-view-model";
import { useSnackbar } from "notistack";
import { useCheckUsages } from "../../hooks/useCheckUsages";
import CadastralModal from "../cadastral-modal";

export const EditUsages: React.FC<{
  vm: ReportViewModel, setUsages: React.Dispatch<React.SetStateAction<UsageDTO[]>>, setPaginationFooter: React.Dispatch<React.SetStateAction<{
    totalReferences: number;
    totalElements: number;
    totalPages: number;
    currentPageNumber: number
  }>>
}> = observer(({ vm, setUsages, setPaginationFooter }) => {
  const [selectedData, setSelectedData] = useState<Array<CadastralBasicPropertyDTO>>([]);
  const [initialRightData, setInitialRightData] = useState<any[]>([]);
  const [usagesLeftData, setUsagesLeftData] = useState<any[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const { checkUsages } = useCheckUsages(vm);
  const [isDataRender, setIsDataRender] = useState<boolean>(false);
  const [loadingProperties, setLoadingProperties] = useState<boolean>(false);

  const transferListSort = (data: CadastralBasicPropertyDTO[]) => {
    return data.sort((a: CadastralBasicPropertyDTO, b: CadastralBasicPropertyDTO) =>
      (a.id?.toLowerCase() ?? "") > (b.id?.toLowerCase() ?? "") ? 1 : -1 || 0
    );
  };

  useEffect(() => {
    if (vm.parcel) {
      const rightDataToSubmit: any[] = [];
      vm.buildingComposition?.usages?.forEach((usage) => {
        const result = vm.parcel?.properties.find(
          (value: any) => `${value.pc1}${value.pc2}${value.car}${value.cc1}${value.cc2}` === usage.cadastralReference
        );
        result && rightDataToSubmit.push(result);
      });
      setInitialRightData(rightDataToSubmit);
      rightDataToSubmit.length === 0 && setIsDataRender(true);
    }
  }, [vm.parcel]);

  const getParcels = async () => {
    await vm.getParcels(vm.userBook.buildingLocationInfo?.address?.cadastralParcelReference ?? "");
    document.getElementById("transfer-usages")?.scrollIntoView();
  };

  useEffect(() => {
    getParcels();
  }, []);

  useEffect(() => {
    if (vm.parcel) {
      setUsagesLeftData(
        initialRightData.length > 0
          ? vm.parcel?.properties.filter((item) => !initialRightData.find((value) => value.id === item.id))
          : vm.parcel?.properties ?? []
      );
      setIsDataRender(true);
    }
  }, [initialRightData]);

  const handleOpenSnackBar = (snackKey: string, variants: undefined | "default" | "error" | "success" | "warning" | "info") => {
    enqueueSnackbar(snackKey, { variant: variants, preventDuplicate: true, key: snackKey, autoHideDuration: 6000 });
  };


  const saveProperties = async () => {
    let book: BookDTO = {};
    try {
      vm.showLoading = true;
      const incompleteBuildings = (await vm.buildingRepository.getIncompletePropertiesIdsFrom(vm.buildingIds ?? [])).incompleteUUIDs ?? [];
      if (incompleteBuildings.length > 35) {
        setLoadingProperties(true);
        await vm.prepare(incompleteBuildings);
      }

      await vm
        .updateProperties({
          bookId: vm.userBook.id ?? "",
          //@ts-ignore
          requestBody: [...new Set(vm.buildingIds)] ?? [],
        })
        .then(async (res) => {
          if (vm.userBook?.buildingComposition?.usages) {
            const res = (await vm.getUsagePage({ id: vm.userBook.id ?? "", limit: 12, offset: 0 }));
            if (res) {
              vm.buildingComposition.usages = res.content
              vm.userBook.buildingComposition.usages = res.content
              setUsages(res.content ?? []);
              setPaginationFooter({ totalReferences: res.totalReferences ?? 0, totalElements: res.totalElements ?? 0, totalPages: res.totalPages ?? 0, currentPageNumber: res.number ?? 0 });
            }
          }
        })
        .catch(async (error) => {
          const errors = ((await vm.handleError(error)) as any).errors;
          if (errors && errors[0] && (errors[0].message as string).includes("already exist")) {
            handleOpenSnackBar(
              vm.translate(
                vm.translate("report.duplicated.book.reference.prop") +
                "\n" +
                errors[0].message.split(
                  "Proxy service > Building Book Manager > Building book > Property already exists in another book for the following cadastral references:\n"
                )[1]
              ),
              "error"
            );
            return undefined;
          }
        });
    } finally {
      if (book && book.buildingComposition) {
        vm.userBook = book;
        vm.buildingComposition = book.buildingComposition;
        checkUsages();
        handleOpenSnackBar(vm.translate("report.block0.buildingIdentification.save.success"), "success");
      }

      vm.isOpenEditUsages = false;
      vm.parcel = undefined;
      vm.showLoading = false;
      setLoadingProperties(false);
      vm.totalUnfinished = 0;
      vm.completed = 0;
    }
  };

  const dialogStrings: ConfirmDialogStrings = {
    title: vm.translate("report.block0.uses.location"),
    content: isDataRender ? (
      <TransferListEditUsage
        initialData={usagesLeftData}
        initialRightData={initialRightData}
        customToolbarPosition="top"
        textGetter={(value: CadastralBasicPropertyDTO) =>
          `${vm.translate("report.block0.buildingIdentification.referenceCadastral.CL")} ${value.name}, ${vm.translate(
            "report.block0.buildingIdentification.referenceCadastral.doorway"
          )} ${value.portalNumber}${value.portalNumberLetter ?? ""}, ${value.block ?? ""} ${vm.translate(
            "report.block0.buildingIdentification.referenceCadastral.floor"
          )} ${value.floor}, ${vm.translate("report.block0.buildingIdentification.referenceCadastral.door")} ${value.door}`
        }
        textGetter2={(value: CadastralBasicPropertyDTO) =>
          `${vm.translate("report.block0.buildingIdentification.referenceCadastral.REF")} ${value.pc1}${value.pc2}${value.car}${value.cc1}${value.cc2}`
        }
        selectedData={selectedData}
        selectedDataSetter={(properties) => {
          vm.buildingIds = properties.map((item: any) => item.id);
          setSelectedData(properties);
        }}
        leftPanelTitle={vm.translate("report.block0.properties")}
        leftPanelSubtitle={"" /* vm.translate("report.block0.properties.subtitle") */}
        rightPanelTitle={vm.translate("report.block0.properties.selected")}
        rightPanelSubtitle={"" /* vm.translate("report.block0.properties.subtitle") */}
        titleColor={Colors.textPrimaryHeaders}
        searchPlaceholder={vm.translate("report.block0.properties.searchBox")}
        listSortingFunction={transferListSort}
        customToolbar={
          <Grid container justifyContent="flex-end" style={{ marginLeft: 5, marginTop: 10 }}>
            <Button
              id="cadastralReferenceGenerate"
              text={vm.translate("save")}
              onClick={() => saveProperties()}
              disabled={selectedData.length === 0}
              textColor={Colors.white}
              backgroundColor={Colors.buttonPrimary}
              hoverColor={Colors.buttonPrimary}
            />
          </Grid>
        }
      />
    ) : (
      <></>
    ),
  };

  return (
    <React.Fragment>
      {dialogStrings.content}
      {loadingProperties && <CadastralModal vm={vm} />}
    </React.Fragment>
  );
});
