import { Router } from "@frui.ts/screens";
import * as exif from "@ginpei/exif-orientation";
import { GridRowId } from "@mui/x-data-grid";
import { action, observable } from "mobx";
import type {
  AccessibilityEvaluationMediaDTO,
  AddressDTO,
  AgentDTO,
  AgentParticipationDTO,
  BuildingDTO,
  CecaSignatureResponse,
  CollegeDTO,
  CommunityDTO,
  EnergyEfficiencyCertificateDTO,
  EnergyEfficiencyImprovementDTO,
  ExtendedSignupTechnician,
  FireSafetyImprovementDTO,
  FullTechnicianParticipationDTO,
  HealthinessImprovementDTO,
  ImprovementActionDTO,
  MaintenancePlanActionDTO,
  MaintenancePlanDTO,
  MediaDTO,
  MediaPrintedDTO,
  MultimediaDTO,
  NoiseImprovementDTO,
  OtherImprovementDTO,
  PhaseDTO,
  ProceedingsDTO,
  ProvinceDTO,
  SecurityImprovementDTO,
  StreetDTO,
  TechnicianDTO,
  TechnicianParticipationDTO,
  TownDTO,
  UrbanDataDTO,
  UsageDTO,
  UsagePlanActionDTO,
  UsagePlanDTO,
  AccessibilityEvaluationDTO,
  BookDTO,
  BuildingCompositionDTO,
  BuildingLocationInfoDTO,
  ImprovementDTO,
  InspectionDTO,
  LicenseDTO,
  BuildingConstructionDescriptionDTO,
} from "../../clients/models";
import {
  EnergyEfficiencyImprovementDataDTOEnergyEfficiencyMeasureTypeEnum,
  PolygonDTOCoordinatesSystemEnum,
  EnergyEfficiencyCertificateDTOTypeEnum,
  BuildingLocationInfoDTOBuildingOccupancyTypeEnum,
  BuildingPolygonDTOCoordinatesSystemEnum,
  ImprovementDTOStatusEnum,
} from "../../clients/models";
import Building from "../../data/entities/building-entity";
import Parcel from "../../data/entities/parcel-entity";
import Street from "../../data/entities/street-entity";
import Town from "../../data/entities/town-entity";
import { BookRepository } from "../../data/repositories/impl/book-repository";
import BuildingRepository from "../../data/repositories/impl/building-repository";
import CadastralRepository from "../../data/repositories/impl/cadastral-repository";
import CommunityRepository from "../../data/repositories/impl/community-repository";
import ImprovementRepository from "../../data/repositories/impl/improvements-actions-repository";
import { MultimediaRepository } from "../../data/repositories/impl/multimedia-repository";
import ParcelsRepository from "../../data/repositories/impl/parcel-repository";
import PropertiesRepository from "../../data/repositories/impl/properties-repository";
import ProvincesRepository from "../../data/repositories/impl/provinces-repository";
import { TechniciansRepository } from "../../data/repositories/impl/technicians-repository";
import TownRepository from "../../data/repositories/impl/town-repository";
import UserManagementRepository from "../../data/repositories/impl/user-management-repository";
import { SessionStoreFactory } from "../../infrastructure/data/session-store-factory";
import { ISessionStore } from "../../infrastructure/data/session-store-interface";
import ConfigurationService from "../../services/configuration-service";
import { UUIDGenerator } from "../../utils/arrayHelper";
import * as BookHelper from "../../utils/book-helper";
import { getDefaultParticipantAgent } from "../../utils/book-helper";
import { FormValidator } from "../../views/reports/reportComponents/block0/urbanData/components/FormValidator";
import { TImprovementRow } from "../../views/reports/reportComponents/globalviews/block2/ReportBlock2Actions";
import FilteredViewModelBase from "../filtered-view-model-base";
import {
  addressDTOMock,
  buildingMock,
  getAccesibilityEvaluationsMock,
  getBookMock,
  getBuildingCompositionDefaults,
  getBuildingConstructionDescriptionDefaults,
  getBuildingLocationInfoDefaults,
  getBuildingLocationInfoMock,
  getEnergeticEfficiencyImprovementDTOMock,
  getEnergyEfficiencyCertificateMock,
  getFireSafetyImprovementDTOMock,
  getHealthinessImprovementDTOMock,
  getImprovementDTOMock,
  getLicenseMock,
  getMediaMock,
  getNoiseImprovementDTOMock,
  getOtherImprovementDTOMock,
  getPhaseMock,
  getProceedingMock,
  getSecurityImprovementMock,
  getUrbanDataMock,
  usageMock,
} from "../profile/data";

import type {
  SetSecurityImprovementRequest,
  SetSavingEnergyImprovementRequest,
  SetFireSafetyImprovementRequest,
  SetHealthinessImprovementRequest,
  SetNoisesImprovementRequest,
  SetOtherImprovementRequest,
  AddImprovementToImprovementActionRequest,
  SearchAgentRequest,
  UpdatePropertiesRequest,
  GetUsagesByBookIdRequest,
  GetUsagePageByBookIdRequest,
} from "../../clients/apis";
import type { ShortComingDTO, TechnicianDTOParticipation } from "../profile/data";

import Compressor from "compressorjs";
import { ImageMedia } from "../../views/profile/system-admin/system-admin-books";
import type { CompleteEnergyEfficiencyImprovementDataDTO } from "../../views/reports/reportComponents/block2/energySection/utils/EnergyModels";
import { fromCompleteEnergyEfficiencyImprovementDataDTOToEnergyEfficiencyImprovementDataDTO } from "../../views/reports/reportComponents/block2/energySection/utils/utils";
import RootViewModel from "../root-view-model";

import ReportDetailViewModel from "./report-detail-view-model";
import ProfileViewModel from "../profile/profile-view-model";
export class ReportFilter {
  @observable name: string = "";
}

@Router.registerRoute({ name: Router.Self, route: "report" })
export default class ReportViewModel extends FilteredViewModelBase<BookDTO, ReportFilter, ReportDetailViewModel> {
  validatorVM = new FormValidator();
  @observable editUseFunctionalDtoArraySpaces: boolean = false;

  @action setSelectedImprovement(improvementToEdit: ImprovementDTO | null) {
    this.selectedImprovementDTO = improvementToEdit;
    if (!improvementToEdit) this.energyEfficiencyMeasureType = null;
  }

  energyEfficiencyMeasureType: EnergyEfficiencyImprovementDataDTOEnergyEfficiencyMeasureTypeEnum | null | undefined = null;

  @observable selectedImprovementDTO: ImprovementDTO | null = null;

  navigationName = "report";
  nameValue = "section.report";
  public showInToolbar: boolean = false;
  parent: RootViewModel = this.parent;

  @observable towns: Town[] | undefined = undefined;
  @observable inspection: InspectionDTO | null = null
  @observable numericInputBuildingCompositonValues = {
    parcelArea: 0,
    builtArea: 0,
    heightOverGround: 0,
    floorsOverGround: 0,
    floorsUnderGround: 0,
    elevatorsCount: 0,
    stairsCount: 0,
    builtOnYear: 0,
    lastRehabYear: 0,
    dwellings: 0,
    dwellingsByFloor: 0,
    parkingCountInBuilding: 0,
    parkingPlacesInBuilding: 0,
    premisesCount: 0,
    storageRoomsCount: 0,
  };
  @observable loadings = {
    block1Tabs: false,
  };

  //testing checkbox new logic variables
  @observable useIdsCollection?: number[] = [];
  @observable showTableSaveButton?: boolean = false;

  @observable isOpenEditUsages: boolean = false;
  @observable isWarningEditUsagesOpen: boolean = false;
  @observable editingNormalizedDescription: BuildingConstructionDescriptionDTO | null = null;

  @observable parcel?: Parcel;
  @observable building?: Building;
  @observable buildingIds?: Array<string>;
  @observable evaluation: boolean = false;
  @observable addUser: boolean = false;
  @observable addImprovement: boolean = false;
  @observable profileTechnician: boolean = false;
  @observable showLoading: boolean = false;
  @observable block1Stepper: number | string = "0";
  @observable userBook: BookDTO = getBookMock();
  @observable buildingComposition: BuildingCompositionDTO = getBuildingCompositionDefaults();
  @observable locationInfo: BuildingLocationInfoDTO = getBuildingLocationInfoMock();
  @observable urbanData: UrbanDataDTO = getUrbanDataMock();
  @observable shortcomings: ShortComingDTO[] = observable.array([]);
  @observable accessibilityEvaluations: AccessibilityEvaluationDTO = getAccesibilityEvaluationsMock();
  @observable iteDocument: MediaDTO = getMediaMock();
  @observable licenses: LicenseDTO[] = observable.array([]);
  @observable iteLicense: LicenseDTO = getLicenseMock();
  @observable improvementActions: ImprovementActionDTO[] = [];
  @observable improvementAction?: ImprovementActionDTO = undefined;
  @observable energeticEfficiencyCertificates: EnergyEfficiencyCertificateDTO = getEnergyEfficiencyCertificateMock();
  @observable finalEnergeticEfficiencyCertificates: EnergyEfficiencyCertificateDTO = getEnergyEfficiencyCertificateMock();
  @observable securityImprovement: SecurityImprovementDTO = getSecurityImprovementMock();
  @observable fireSafetyImprovementDTO: FireSafetyImprovementDTO = getFireSafetyImprovementDTOMock();
  @observable
  energeticEfficiencyImprovementDTO: EnergyEfficiencyImprovementDTO = getEnergeticEfficiencyImprovementDTOMock();
  @observable noiseImprovementDTO: NoiseImprovementDTO = getNoiseImprovementDTOMock();
  @observable healthinessImprovementDTO: HealthinessImprovementDTO = getHealthinessImprovementDTOMock();
  @observable otherImprovementDTO: OtherImprovementDTO = getOtherImprovementDTOMock();
  @observable improvementDTO: ImprovementDTO = getImprovementDTOMock();
  @observable improvementsDTO: ImprovementDTO[] = observable.array([]);
  @observable proceeding: ProceedingsDTO = getProceedingMock();
  @observable editingInspection: boolean = false;
  @observable savedInspection: boolean = false;
  @observable showInspectionModal: boolean = false;
  @observable bookAgents: AgentParticipationDTO[] = [];
  @observable bookTechnicians: TechnicianDTOParticipation[] = observable.array([]);
  @observable improvementAdded: boolean | undefined = undefined;
  @observable selectedBuildingCompositionUsage: number | undefined = undefined;
  @observable userData: TechnicianDTO | undefined = undefined;
  @observable selectedCollege: CollegeDTO | undefined = undefined;

  @observable graphicDocs: File[] = observable.array([]);
  @observable urbanMedia: File[] = observable.array([]);
  @observable proceedingFiles: File[] = [];
  @observable iteInspectionFiles: File[] = [];
  @observable ieeInspectionFiles: File[] = [];
  @observable complementaryFiles: File[] = [];
  @observable inspectionFiles: File[] = [];
  @observable iteLicenseDocuments: File[] = [];
  @observable block1ComplementaryFiles: File[] = [];
  @observable community: CommunityDTO | undefined = undefined;
  @observable province: ProvinceDTO | undefined = undefined;
  @observable town: TownDTO | undefined = undefined;
  @observable street: StreetDTO | undefined = undefined;

  @observable manualPci: boolean = false;
  @observable block1LicenseComplementaryMultimedias: MultimediaDTO[] = observable.array([]);
  @observable manualPciMaintenance: number[] = observable.array([]);
  @observable manualFacade: number[] = observable.array([]);
  @observable manualCovered: number[] = observable.array([]);
  @observable manualInclinedCovered: number[] = observable.array([]);
  @observable protectFire: boolean = false;
  @observable bookMedia: MediaDTO[] = observable.array([]);
  @observable accessibilityEvaluationsFiles: File[] = observable.array([]);

  @observable selectedTechnicianParticipation: FullTechnicianParticipationDTO | undefined = undefined;
  @observable college?: CollegeDTO;
  @observable profession: string = "";
  @observable inviteEmail: string = "";
  @observable buildingBookId: string = "";
  @observable invitedTechnicianEmail: string = "";
  @observable invitedTechnicianName: string = "";

  @observable manualUsage: UsageDTO = usageMock();
  @observable editManualUsage: boolean = false;
  @observable manualBuildingDTO: BuildingDTO = buildingMock();
  @observable manualAddress: AddressDTO = addressDTOMock();
  @observable manualUsages: UsageDTO[] = observable.array([]);
  @observable manualCadastralReferenceDescription: string = "";
  @observable manualCadastralReference: string = "";
  @observable hideInspectionSave = false;

  @observable role: string = "";

  @observable shortComingsToShow: any[] = observable.array([]);
  @observable shortComingsBody: ShortComingDTO = {};
  @observable openEnergeticImprovementForm: boolean = false;
  @observable openImprovementDetail: boolean = false;
  @observable loadingBook: boolean = false;

  @observable buildingConservationImprovementsTable: TImprovementRow[] = [];
  @observable energySavingImprovementsTable: TImprovementRow[] = [];
  @observable otherImprovementsTable: TImprovementRow[] = [];
  @observable finalImprovementsTable: TImprovementRow[] = [];
  @observable errorBookCreation: boolean = false;
  //maintenance
  @observable maintenancePlanDtoArray: MaintenancePlanDTO = { actions: [] };
  @observable carpentryMaintenance: MaintenancePlanActionDTO[] = observable.array([]);
  @observable fireMaintenance: MaintenancePlanActionDTO[] = observable.array([]);
  @observable facadeMaintenance: MaintenancePlanActionDTO[] = observable.array([]);
  @observable telecomMaintenance: MaintenancePlanActionDTO[] = observable.array([]);
  @observable
  mainFacadeprotectiveElementsMaintenance: MaintenancePlanActionDTO[] = observable.array([]);
  @observable
  otherFacadesprotectiveElementsMaintenance: MaintenancePlanActionDTO[] = observable.array([]);
  @observable gasFuelMaintenance: MaintenancePlanActionDTO[] = observable.array([]);
  @observable coveredMaintenance: MaintenancePlanActionDTO[] = observable.array([]);
  @observable divisonMaintenance: MaintenancePlanActionDTO[] = observable.array([]);
  @observable facingMaintenance: MaintenancePlanActionDTO[] = observable.array([]);
  @observable foundationMaintenance: MaintenancePlanActionDTO[] = observable.array([]);
  @observable electricityMaintenance: MaintenancePlanActionDTO[] = observable.array([]);
  @observable structureMaintenance: MaintenancePlanActionDTO[] = observable.array([]);
  @observable acsMaintenance: MaintenancePlanActionDTO[] = observable.array([]);
  @observable supplyWaterMaintenance: MaintenancePlanActionDTO[] = observable.array([]);
  @observable heatingMaintenance: MaintenancePlanActionDTO[] = observable.array([]);
  @observable customMaintenance: any[] = observable.array([]);
  @observable tryChecks: GridRowId[] = observable.array([]);
  @observable sanitationMaintenance: any;
  @observable elevatorsMaintenance: MaintenancePlanActionDTO[] = observable.array([]);
  @observable radonMaintenance: MaintenancePlanActionDTO[] = observable.array([]);
  @observable cecaSignatureConfiguration: CecaSignatureResponse | null = null;
  @observable needPayment: boolean = false;
  @observable showFileuploader: boolean = true;
  @observable phaseFormOpen: boolean = false;
  @observable phaseItem: PhaseDTO = getPhaseMock();
  //use
  @observable useFunctionalDtoArray: UsagePlanDTO = {
    year: new Date().getFullYear(),
    actions: [],
    privateAndCommonSpaces: "",
  };
  @observable carpentryUse: UsagePlanActionDTO[] = observable.array([]);
  @observable fireUse: UsagePlanActionDTO[] = observable.array([]);
  @observable facadeUse: UsagePlanActionDTO[] = observable.array([]);
  @observable telecomUse: UsagePlanActionDTO[] = observable.array([]);
  @observable mainFacadeprotectiveElementsUse: UsagePlanActionDTO[] = observable.array([]);
  @observable otherFacadesprotectiveElementsUse: UsagePlanActionDTO[] = observable.array([]);
  @observable gasFuelUse: UsagePlanActionDTO[] = observable.array([]);
  @observable coveredUse: UsagePlanActionDTO[] = observable.array([]);
  @observable divisonUse: UsagePlanActionDTO[] = observable.array([]);
  @observable facingUse: UsagePlanActionDTO[] = observable.array([]);
  @observable foundationUse: UsagePlanActionDTO[] = observable.array([]);
  @observable electricityUse: UsagePlanActionDTO[] = observable.array([]);
  @observable structureUse: UsagePlanActionDTO[] = observable.array([]);
  @observable acsUse: UsagePlanActionDTO[] = observable.array([]);
  @observable supplyWaterUse: UsagePlanActionDTO[] = observable.array([]);
  @observable heatingUse: UsagePlanActionDTO[] = observable.array([]);
  @observable customUse: UsagePlanActionDTO[] = observable.array([]);
  @observable sanitationUse: UsagePlanActionDTO[] = observable.array([]);
  @observable elevatorsUse: UsagePlanActionDTO[] = observable.array([]);
  @observable radonUse: UsagePlanActionDTO[] = observable.array([]);

  @observable selectedCommunityManualEntry: string | undefined;

  @observable errorUsages: boolean = true;
  @observable errorLocation: boolean = true;
  @observable errorCadastralReference = true;

  @observable errorEfficiency: boolean = false;
  @observable error1Efficiency: boolean = false;
  @observable error2Efficiency: boolean = false;
  @observable error3Efficiency: boolean = false;
  @observable openPaymentForm: boolean = false;
  @observable currentAgent: AgentParticipationDTO = getDefaultParticipantAgent();
  @observable editingParticipation: boolean = false;

  @observable coordinates: { latitude: number; longitude: number } = { longitude: 0, latitude: 0 };
  @observable paymentButton: boolean = false;

  @observable improvementActionFiles: File[] = observable.array([]);

  @observable completed = 0;
  @observable totalUnfinished = 0;
  @observable prepareRetryLimit = 0;
  @observable failChain = 0;

  @observable buildingCompositionFrontPageFile: File | undefined;
  @observable buildingCompositionFiles: File[] | undefined;
  @observable buildingCompositionFrontPageMedia: MultimediaDTO | undefined;
  @observable buildingCompositionMultimedia: MultimediaDTO[] | undefined;

  protected search(query: string): void { }

  loadData(): void | Promise<any> { }

  reportSessionStorage: ISessionStore = SessionStoreFactory.getSessionStore(); // TEMPORARY
  tabs: Array<string> = ["block0", "block1", "block2"];
  @observable activeTab: string = "block0";
  @observable previousTab: string = "block0";
  @observable isBlockSaved: boolean = false;
  nHomes = 0;
  nBuildings = 0;

  constructor(
    public configurationService: ConfigurationService,
    public buildingRepository: BuildingRepository,
    public parcelsRepository: ParcelsRepository,
    public communityRepository: CommunityRepository,
    public provincesRepository: ProvincesRepository,
    public bookRepository: BookRepository,
    public improvementActionsRepository: ImprovementRepository,
    public multimediaRepository: MultimediaRepository,
    public userManagementRepository: UserManagementRepository,
    public townRepository: TownRepository,
    public techniciansRepository: TechniciansRepository,
    private detailFactory: ReturnType<typeof ReportDetailViewModel.Factory>,
    public cadastralRepository: CadastralRepository,
    public propertiesRepository: PropertiesRepository
  ) {
    super();
  }

  async onActivate() {
    super.onActivate();
    this.bookTechnicians = [];
    this.parcel = undefined;
    this.manualPci = false;
    this.manualPciMaintenance = [];
    this.manualFacade = [];
    this.manualCovered = [];
    this.manualInclinedCovered = [];
    SessionStoreFactory.getSessionStore().getToken()?.split(".")[1] && this.parent.getTechnicianData();
  }

  async onDeactivate(isClosing: boolean): Promise<void> {
    if (isClosing) {
      this.parent.editingBook = false;
      this.parent.selectedBook = undefined;
    }
  }

  openDetail(item: BookDTO): void | Promise<void> {
    const detail = this.detailFactory(this.bookRepository, item);
    this.tryActivateChild(detail);
  }

  openNew(): void | Promise<void> {
    this.parent.selectedBook = getBookMock();
    const detail = this.detailFactory(this.bookRepository, this.parent.selectedBook);
    this.tryActivateChild(detail);
  }

  @action getParcels = async (cadastralReference: string) => {
    //const province = await this.provincesRepository.getProvinceByCode("50") as any;
    //const books = await this.bookRepository.getBookById("1");
    this.loadingBook = true;
    try {
      const result: Parcel = await this.parcelsRepository.getParcelByCadastralReference(cadastralReference);
      this.parcel = result;
    } catch (error) {
      this.loadingBook = false;
      return false;
    }
    this.loadingBook = false;
    return true;
  };

  createExternalMedia = async (media: MediaDTO[]): Promise<MultimediaDTO[]> => {
    return Promise.all(
      media.map(
        async (m: MediaDTO) =>
          await this.multimediaRepository.createExternalmedia({
            description: "",
            extension: m.format,
            id: m.id,
            length: 0,
            mediaType: `image/${m.format}`,
            name: m.name,
            url: m.url,
          })
      )
    );
  };

  @action getTown = async (provinceCode: string, townCode: string, streetname: string) => {
    const town = await (await this.provincesRepository.getAllTownByProvinceCode(provinceCode ?? "")).find((t: TownDTO) => t.code === townCode);
    return (await this.townRepository.getStreetsByTownIdAndName(town?.id ?? "", streetname).catch((err) => [] as Street[])).find(
      (street: StreetDTO) => street.name?.toLocaleLowerCase() === streetname.toLocaleLowerCase()
    );
  };

  @action updateProperties = async (requestParameters: UpdatePropertiesRequest) => {
    const book = await this.bookRepository.updateProperties(requestParameters);
    return book;
  };

  @action getBuildingManual = async () => {
    this.loadingBook = true;
    const resultBook = await this.initializeNewBookManual();
    if (resultBook) {
      if (this.locationInfo.address) {
        this.locationInfo.address.latitude = Number(this.coordinates.latitude?.toString()?.replaceAll(",", ".")) ?? 0;
        this.locationInfo.address.longitude = Number(this.coordinates.longitude?.toString()?.replaceAll(",", ".")) ?? 0;
        if (!this.locationInfo.hasOwnProperty("referenceDescription")) this.locationInfo.referenceDescription = "";
        this.locationInfo.buildingPolygon = [
          {
            content: [
              {
                lat: this.locationInfo.address?.latitude ?? 0,
                lng: this.locationInfo.address?.longitude ?? 0,
              },
            ],
            coordinatesSystem: BuildingPolygonDTOCoordinatesSystemEnum.Epsg4258,
          },
        ];
        this.locationInfo.parcelPolygon = [
          {
            content: [
              {
                lat: this.locationInfo.address?.latitude ?? 0,
                lng: this.locationInfo.address?.longitude ?? 0,
              },
            ],
            coordinatesSystem: PolygonDTOCoordinatesSystemEnum.Epsg4258,
          },
        ];
        await this.bookRepository.setBuildingComposition(resultBook.id!, this.buildingComposition).catch(err => console.error(err));
        await this.bookRepository.setLocation(resultBook.id!, this.locationInfo).catch(err => console.error(err));
        resultBook.buildingComposition = this.buildingComposition;
        resultBook.buildingLocationInfo = this.locationInfo;
        resultBook.buildingConstructionDescription = getBuildingConstructionDescriptionDefaults(resultBook.buildingConstructionDescription);
        this.userBook = resultBook;
        this.parent.editingBook = true;
        this.parent.isManual = false;
        (this.parent.children.find((c) => c.navigationName === "profile") as ProfileViewModel)?.searchProfileBooks({});
        this.openDetail(this.userBook);
      }
    }
    this.loadingBook = false;
  };

  initializeNewBookManual = async () => {
    if (this.parent.technicianData?.credentials) {
      this.manualBuildingDTO.techniciansParticipation?.push({
        id: this.parent.technicianData?.credentials,
        college: this.selectedCollege?.id ?? this.parent.technicianData?.collegiated?.[0]?.college?.id ?? "",
        participation: 100,
      });
      return this.bookRepository
        .initBookManual({
          buildingDTO: this.manualBuildingDTO,
          collegeId: this.selectedCollege?.id ?? this.parent.technicianData?.collegiated?.[0]?.college?.id ?? "",
        })
        .catch(async (err) => {
          const errors = ((await this.handleError(err)) as any).errors;
          if (errors && errors[0] && (errors[0].message as string).includes("already exist")) {
            alert(
              this.translate("report.duplicated.book.reference.prop") +
              "\n" +
              errors[0].message.split(
                "Proxy service > Building Book Manager > Building book > Property already exists in another book for the following cadastral references:\n"
              )[1]
            );
            return undefined;
          }
        });
    }
  };

  difference = (a: any, b: any) => {
    const s = new Set(b);
    return a.filter((x: any) => !s.has(x));
  };

  @action resetStates = () => {
    this.towns = undefined;
    this.inspection = null;
    this.numericInputBuildingCompositonValues = {
      parcelArea: 0,
      builtArea: 0,
      heightOverGround: 0,
      floorsOverGround: 0,
      floorsUnderGround: 0,
      elevatorsCount: 0,
      stairsCount: 0,
      builtOnYear: 0,
      lastRehabYear: 0,
      dwellings: 0,
      dwellingsByFloor: 0,
      parkingCountInBuilding: 0,
      parkingPlacesInBuilding: 0,
      premisesCount: 0,
      storageRoomsCount: 0,
    };
    //// this.loadings = {
    ////   block1Tabs: false,
    //// };
    this.parcel = undefined;
    this.building = undefined;
    this.buildingIds = [];
    this.evaluation = false;
    this.addUser = false;
    this.addImprovement = false;
    this.profileTechnician = false;
    this.useIdsCollection = [];
    this.showTableSaveButton = true;
    //// this.showLoading = false;
    this.block1Stepper = "0";
    this.userBook = getBookMock();
    this.buildingComposition = getBuildingCompositionDefaults();
    this.locationInfo = getBuildingLocationInfoMock();
    this.urbanData = getUrbanDataMock();
    this.shortcomings = observable.array([]);
    this.accessibilityEvaluations = getAccesibilityEvaluationsMock();
    this.iteDocument = getMediaMock();
    this.licenses = observable.array([]);
    this.iteLicense = getLicenseMock();
    this.improvementActions = [];
    this.improvementAction = undefined;
    this.energeticEfficiencyCertificates = getEnergyEfficiencyCertificateMock();
    this.finalEnergeticEfficiencyCertificates = getEnergyEfficiencyCertificateMock();
    this.securityImprovement = getSecurityImprovementMock();
    this.fireSafetyImprovementDTO = getFireSafetyImprovementDTOMock();
    this.energeticEfficiencyImprovementDTO = getEnergeticEfficiencyImprovementDTOMock();
    this.noiseImprovementDTO = getNoiseImprovementDTOMock();
    this.healthinessImprovementDTO = getHealthinessImprovementDTOMock();
    this.otherImprovementDTO = getOtherImprovementDTOMock();
    this.improvementDTO = getImprovementDTOMock();
    this.improvementsDTO = observable.array([]);
    this.proceeding = getProceedingMock();
    this.editingInspection = false;
    this.savedInspection = false;
    this.showInspectionModal = false;
    this.bookAgents = [];
    this.bookTechnicians = observable.array([]);
    this.improvementAdded = undefined;
    this.selectedBuildingCompositionUsage = undefined;
    this.userData = undefined;
    this.selectedCollege = undefined;

    this.graphicDocs = observable.array([]);
    this.urbanMedia = observable.array([]);
    this.proceedingFiles = [];
    this.iteInspectionFiles = [];
    this.ieeInspectionFiles = [];
    this.complementaryFiles = [];
    this.inspectionFiles = [];
    this.iteLicenseDocuments = [];
    this.block1ComplementaryFiles = [];
    this.community = undefined;
    this.province = undefined;
    this.town = undefined;
    this.street = undefined;

    this.manualPci = false;
    this.block1LicenseComplementaryMultimedias = observable.array([]);
    this.manualPciMaintenance = observable.array([]);
    this.manualFacade = observable.array([]);
    this.manualCovered = observable.array([]);
    this.manualInclinedCovered = observable.array([]);
    this.protectFire = false;
    this.bookMedia = observable.array([]);
    this.accessibilityEvaluationsFiles = observable.array([]);

    this.selectedTechnicianParticipation = undefined;
    this.college = undefined;
    this.profession = "";
    this.inviteEmail = "";
    this.buildingBookId = "";
    this.invitedTechnicianEmail = "";
    this.invitedTechnicianName = "";

    this.hideInspectionSave = false;

    this.role = "";

    this.shortComingsToShow = observable.array([]);
    this.shortComingsBody = {};
    this.openEnergeticImprovementForm = false;
    this.openImprovementDetail = false;
    //// this.loadingBook = false;

    this.buildingConservationImprovementsTable = [];
    this.energySavingImprovementsTable = [];
    this.otherImprovementsTable = [];
    this.finalImprovementsTable = [];

    //// maintenance
    this.maintenancePlanDtoArray = { actions: [] };
    this.carpentryMaintenance = observable.array([]);
    this.fireMaintenance = observable.array([]);
    this.facadeMaintenance = observable.array([]);
    this.telecomMaintenance = observable.array([]);
    this.mainFacadeprotectiveElementsMaintenance = observable.array([]);
    this.otherFacadesprotectiveElementsMaintenance = observable.array([]);
    this.gasFuelMaintenance = observable.array([]);
    this.coveredMaintenance = observable.array([]);
    this.divisonMaintenance = observable.array([]);
    this.facingMaintenance = observable.array([]);
    this.foundationMaintenance = observable.array([]);
    this.electricityMaintenance = observable.array([]);
    this.structureMaintenance = observable.array([]);
    this.acsMaintenance = observable.array([]);
    this.supplyWaterMaintenance = observable.array([]);
    this.heatingMaintenance = observable.array([]);
    this.customMaintenance = observable.array([]);
    this.tryChecks = observable.array([]);
    this.sanitationMaintenance = undefined;
    this.elevatorsMaintenance = observable.array([]);
    this.radonMaintenance = observable.array([]);
    this.cecaSignatureConfiguration = null;
    this.needPayment = false;

    //use
    this.useFunctionalDtoArray = {
      year: new Date().getFullYear(),
      actions: [],
      privateAndCommonSpaces: "",
    };
    this.carpentryUse = observable.array([]);
    this.fireUse = observable.array([]);
    this.facadeUse = observable.array([]);
    this.telecomUse = observable.array([]);
    this.mainFacadeprotectiveElementsUse = observable.array([]);
    this.otherFacadesprotectiveElementsUse = observable.array([]);
    this.gasFuelUse = observable.array([]);
    this.coveredUse = observable.array([]);
    this.divisonUse = observable.array([]);
    this.facingUse = observable.array([]);
    this.foundationUse = observable.array([]);
    this.electricityUse = observable.array([]);
    this.structureUse = observable.array([]);
    this.acsUse = observable.array([]);
    this.supplyWaterUse = observable.array([]);
    this.heatingUse = observable.array([]);
    this.customUse = observable.array([]);
    this.sanitationUse = observable.array([]);
    this.elevatorsUse = observable.array([]);
    this.radonUse = observable.array([]);

    this.errorEfficiency = false;
    this.error1Efficiency = false;
    this.error2Efficiency = false;
    this.error3Efficiency = false;
    this.openPaymentForm = false;

    this.tabs = ["block0", "block1", "block2"];
    this.activeTab = "block0";
    this.nHomes = 0;
    this.nBuildings = 0;
  };

  @action removeImageFromBuildingComposition(fileToDelete: string) {
    this.buildingComposition.media!! = this.buildingComposition!!.media!!.filter((media) => media.id !== fileToDelete);
    this.userBook.buildingComposition!!.media = this.userBook.buildingComposition!!.media!!.filter((media) => media.id !== fileToDelete);
  }

  @action getBuildingMoreCollegiateds = async (buildings: any, media: MediaDTO[]) => {
    this.loadingBook = true;

    const incompleteBuildings = (await this.buildingRepository.getIncompletePropertiesIdsFrom(buildings)).incompleteUUIDs ?? [];
    if (incompleteBuildings.length > 35) await this.prepare(incompleteBuildings);

    const res = await this.bookRepository
      .initBook({
        collegeId: this.selectedCollege?.id ?? this.parent.technicianData!.collegiated![0].college!.id ?? "",
        requestBody: buildings,
      })
      .catch(async (err) => {
        const errors = ((await this.handleError(err)) as any).errors;
        if (errors && errors[0] && (errors[0].message as string).includes("already exist")) {
          alert(
            this.translate("report.duplicated.book.reference.prop") +
            "\n" +
            errors[0].message.split(
              "Proxy service > Building Book Manager > Building book > Property already exists in another book for the following cadastral references:\n"
            )[1]
          );
          return undefined;
        } else if (errors && errors[0] && (errors[0].message as string).includes("Parcel does not have usages")) {
          alert(this.translate("initbook.error.parcel_does_not_have_usages"));
          return undefined;
        }
      });

    if (res === undefined) {
      this.loadingBook = false;
      return;
    }
    this.userBook = res;
    let bc = this.userBook.buildingComposition;
    if (bc) {
      const multimedia = await this.createExternalMedia([media?.[0]]).catch(e => console.log(e));
      if (multimedia) {
        bc.media = BookHelper.fromMultimediaToMediaAll(multimedia);
        this.userBook = await this.bookRepository.setBuildingComposition(this.userBook.id!, bc).then((res) => {
          let book = this.userBook;
          book.buildingComposition = bc;
          return book
        });
      }
      this.userBook.buildingConstructionDescription = getBuildingConstructionDescriptionDefaults(this.userBook.buildingConstructionDescription);
      this.buildingComposition = this.userBook.buildingComposition!;
      this.locationInfo = this.userBook.buildingLocationInfo!;
    }
    const profilevm = this.parent.children.find((c) => c.navigationName === "profile") as ProfileViewModel;
    await profilevm?.searchProfileBooks(profilevm.searchOptions ?? {});
    this.openDetail(this.userBook);
    this.parent.editingBook = true;
    this.loadingBook = false;
  };

  handleError = async (error: any) => {
    const toStream = new Response(error.body);
    const errorResponse = (await toStream.json()) as any;
    return errorResponse;
  };

  verifyCollegiated = () => {
    if (this.parent.technicianData?.credentials) {
      if (this.parent.technicianData.collegiated!!.length > 1) {
        this.userData = this.parent.technicianData as TechnicianDTO;
        return false;
      } else {
        return true;
      }
    }
  };

  updateBuildingComposition = async (bookId: string, buildingComposition: BuildingCompositionDTO) => {
    this.numericInputBuildingCompositonValues.parcelArea && (buildingComposition.parcelArea = this.numericInputBuildingCompositonValues.parcelArea);
    this.numericInputBuildingCompositonValues.builtArea && (buildingComposition.builtArea = this.numericInputBuildingCompositonValues.builtArea);
    this.numericInputBuildingCompositonValues.builtOnYear && (buildingComposition.builtOnYear = this.numericInputBuildingCompositonValues.builtOnYear);
    this.numericInputBuildingCompositonValues.dwellings && (buildingComposition.dwellings = this.numericInputBuildingCompositonValues.dwellings);
    this.numericInputBuildingCompositonValues.dwellingsByFloor &&
      (buildingComposition.dwellingsByFloor = this.numericInputBuildingCompositonValues.dwellingsByFloor);
    this.numericInputBuildingCompositonValues.elevatorsCount &&
      (buildingComposition.elevatorsCount = this.numericInputBuildingCompositonValues.elevatorsCount);
    this.numericInputBuildingCompositonValues.floorsOverGround &&
      (buildingComposition.floorsOverGround = this.numericInputBuildingCompositonValues.floorsOverGround);
    this.numericInputBuildingCompositonValues.floorsUnderGround &&
      (buildingComposition.floorsUnderGround = this.numericInputBuildingCompositonValues.floorsUnderGround);
    this.numericInputBuildingCompositonValues.heightOverGround &&
      (buildingComposition.heightOverGround = this.numericInputBuildingCompositonValues.heightOverGround);
    this.numericInputBuildingCompositonValues.lastRehabYear &&
      (buildingComposition.lastRehabYear = this.numericInputBuildingCompositonValues.lastRehabYear);
    this.numericInputBuildingCompositonValues.parkingCountInBuilding &&
      (buildingComposition.parkingCountInBuilding = this.numericInputBuildingCompositonValues.parkingCountInBuilding);
    this.numericInputBuildingCompositonValues.parkingPlacesInBuilding &&
      (buildingComposition.parkingPlacesInBuilding = this.numericInputBuildingCompositonValues.parkingPlacesInBuilding);
    this.numericInputBuildingCompositonValues.premisesCount &&
      (buildingComposition.premisesCount = this.numericInputBuildingCompositonValues.premisesCount);
    this.numericInputBuildingCompositonValues.stairsCount && (buildingComposition.stairsCount = this.numericInputBuildingCompositonValues.stairsCount);
    this.numericInputBuildingCompositonValues.storageRoomsCount &&
      (buildingComposition.storageRoomsCount = this.numericInputBuildingCompositonValues.storageRoomsCount);
    return await this.bookRepository.setBuildingComposition(bookId, buildingComposition);
  };

  updateLocationInfo = async (bookId: string, locationInfo: BuildingLocationInfoDTO) => {
    this.loadingBook = true;
    return await this.bookRepository.setLocation(bookId, locationInfo);
  };

  updateBuildingUrbanData = (bookId: string, urbanData: UrbanDataDTO) => {
    return this.bookRepository.setUrbanData(bookId, urbanData);
  };

  addAgentParticipation = (bookId: string, agentParticipation: AgentParticipationDTO): Promise<AgentParticipationDTO> => {
    if (!agentParticipation.hasOwnProperty("id") || agentParticipation.id === undefined) agentParticipation.id = "";
    return this.bookRepository.addAgent({ id: bookId, agentParticipationDTO: agentParticipation });
  };

  updateTechnicianParticipation = (bookId: string, technicianParticipation: TechnicianParticipationDTO) => {
    return this.bookRepository.addTechnician(bookId, technicianParticipation);
  };

  @action.bound
  uploadNewMedia = async (file: Blob) => {
    return await this.createMediaWithoutApi(file);
  };

  handleErrorEfficiency = () => {
    this.energeticEfficiencyCertificates.co2Emissions!.qualifier === undefined && (this.errorEfficiency = true);
    this.energeticEfficiencyCertificates!.primaryEnergyNoRenewableConsumptionDTO!.qualifier === undefined && (this.error1Efficiency = true);
    this.energeticEfficiencyCertificates.heatingDemand!.qualifier === undefined && (this.error2Efficiency = true);
    this.energeticEfficiencyCertificates.coolingDemand!.qualifier === undefined && (this.error3Efficiency = true);
  };

  prepare = async (ids: string[]) => {
    if (this.prepareRetryLimit === 4) {
      this.completed = 0;
      this.totalUnfinished = 0;
      this.prepareRetryLimit = 0;
      this.failChain = 0;
      return;
    }
    const unfinished = [];
    if (this.completed === 0) this.totalUnfinished = ids.length;
    for (const id of ids) {
      if (this.failChain === 25) {
        this.prepareRetryLimit = this.prepareRetryLimit + 1;
        break;
      }
      await this.sleep(500);
      try {
        const property = await this.propertiesRepository.getPropertyByReference(id);
        if (property.complete) {
          this.completed = this.completed + 1;
        } else {
          unfinished.push(id);
        }
      } catch (e) {
        this.failChain = this.failChain + 1;
        unfinished.push(id);
      }
    }
    if (unfinished.length > 0) {
      await this.prepare(unfinished);
    }
    this.completed = 0;
    this.totalUnfinished = 0;
    this.prepareRetryLimit = 0;
    this.failChain = 0;
  };

  handleResponse = async (res: any): Promise<MultimediaDTO> => {
    const toStream = new Response(res.body);
    const response = await toStream.json();
    return response as MultimediaDTO;
  };

  @action.bound
  async createMediaWithoutApi(file: Blob): Promise<MultimediaDTO> {
    const compressedFile = await this.reduceFileWeight(file as File);
    const newFile = await this.multimediaRepository.createMedia(compressedFile);
    return await this.handleResponse(newFile);
  }
  @action.bound
  async createMediaCustomName(file: Blob, name: string): Promise<MultimediaDTO> {
    const compressedFile = await this.reduceFileWeight(file as File);
    const newFile = await this.multimediaRepository.createMedia(compressedFile, `${name}.png`);
    return await this.handleResponse(newFile);
  }

  @action.bound
  async createMediaWithoutApiManual(file: Blob, urbanPlan?: boolean): Promise<MultimediaDTO> {
    let newFile: MultimediaDTO = {};
    const compressedFile = await this.reduceFileWeight(file as File);
    if (urbanPlan) newFile = await this.multimediaRepository.createMedia(compressedFile, "map_screenshot.png");
    else newFile = await this.multimediaRepository.createMedia(compressedFile);
    return await this.handleResponse(newFile);
  }

  @action.bound
  async createMediaWithoutApiPortrait(file: Blob, isPortrait?: boolean): Promise<MultimediaDTO> {
    let newFile: MultimediaDTO = {};
    const compressedFile = await this.reduceFileWeight(file as File);
    if (isPortrait) newFile = await this.multimediaRepository.createMedia(compressedFile, "portrait_image.png");
    else newFile = await this.multimediaRepository.createMedia(compressedFile);
    return await this.handleResponse(newFile);
  }

  @action.bound
  async createMediaWithoutApiWithName(file: Blob, name: string, section: string): Promise<boolean> {
    this.loadingBook = true;
    try {
      const compressedFile = await this.reduceFileWeight(file as File);
      const newFile = await this.multimediaRepository.createMedia(compressedFile);
      let media = BookHelper.fromMultimediaToMedia(await this.handleResponse(newFile));
      media.name = name;
      if (section === "maintenance-contracts") {
        await this.bookRepository.addMaintenanceContracts({
          id: this.userBook!!.id!!,
          mediaDTO: media,
        }).then(res => {
          this.userBook.maintenanceContracts = [...this.userBook.maintenanceContracts ?? [], media];
        })
      } else if (section === "incidences-maintenance") {
        // await this.bookRepository.addActionRegistryDocumentation({id: this.userBook!!.id!!, mediaDTO: media});
      } else if (section === "building-performance-record") {
        await this.bookRepository.addActionRegistryDocumentation({
          id: this.userBook!!.id!!,
          mediaDTO: media,
        }).then((res) => {
          this.userBook.actionRegistries = [...this.userBook.actionRegistries ?? [], media];
        })
      } else if (section === "recommendations-best-practices") {
        await this.bookRepository.addRecommendationsAndBestPractices({
          id: this.userBook!!.id!!,
          mediaDTO: media,
        }).then(res => {
          this.userBook.bestPractices = [...this.userBook.bestPractices ?? [], media];
        })
      }
      this.loadingBook = false;
      return true;
    } catch (e) {
      this.loadingBook = false;
      return false;
    }
  }

  async findMapScreenshotManual(external_media?: MultimediaDTO) {
    const media = this.buildingComposition?.media ?? [];
    const multimedia = await Promise.all(media.map(async (m) => await this.getMultimediaById(m.id!)));
    const found = multimedia.find((m) => m.name === "map_screenshot" || m.description === "map_screenshot");
    if (external_media) {
      this.removeImageFromBuildingComposition(found?.id ?? "");
      this.buildingComposition.media = [...(this.buildingComposition.media ?? []), BookHelper.fromMultimediaToMedia(external_media)];
    }
  }

  async findMapScreenshot(m?: MultimediaDTO) {
    const media = this.buildingComposition?.media ?? [];
    const multimedia = await Promise.all(media.map(async (m) => await this.getMultimediaById(m.id!)));
    const found = multimedia.find((m) => m.name === "map_screenshot");
    return found;
  }

  @action.bound
  async saveMapBuilding(file: Blob, description: string) {
    const found = await this.findMapScreenshot();
    if (!found) {
      this.saveNewMapMedia(file, description);
    } else {
      this.updateMapMedia(file, description, found.id!!);
    }
  }

  @action.bound
  async updateMapMedia(file: Blob, description: string, id: string) {
    this.deleteFile(id);
    this.removeImageFromBuildingComposition(id);
    this.saveNewMapMedia(file, description);
  }

  @action.bound
  async saveNewMapMedia(file: Blob, description: string) {
    this.loadingBook = true;
    try {
      const newFile = await this.multimediaRepository.createMedia(file, description + ".png");
      let media = BookHelper.fromMultimediaToMedia(await this.handleResponse(newFile));
      media.name = description;
      this.buildingComposition.media = [...(this.buildingComposition.media ?? []), media];
      await this.updateBuildingComposition(this.userBook.id ?? "", this.buildingComposition).then(res => {
        this.userBook.buildingComposition = this.buildingComposition
      })

      this.loadingBook = false;
    } catch (e) {
      this.loadingBook = false;
    }
  }

  @action.bound
  async createMediaWithoutApiAndReturnMedia(file: Blob): Promise<MultimediaDTO> {
    return BookHelper.fromMultimediaToMedia(await this.createMediaWithoutApi(file));
  }

  createNewUser = (user: ExtendedSignupTechnician) => {
    return this.userManagementRepository.technicianSignUpRequest({
      extendedSignupTechnician: user,
    });
  };

  addIte = (license: LicenseDTO) => {
    return this.bookRepository.addLicense(this.userBook.id! ?? "", license);
  };

  @action.bound
  getMultimediaById = async (id: string) => await this.multimediaRepository.getMediaById(id);

  @action saveInspection = async () => {
    try {
      if (this.inspection) {
        this.loadingBook = true;
        this.nHomes && (this.inspection!!.generalData!!.inspectedHouseHolds = this.nHomes);
        this.nHomes = 0;
        this.nBuildings && (this.inspection.generalData!.inspectedPremisedOrOtherUsages = this.nBuildings);
        this.nBuildings = 0;
        this.shortcomings = this.shortcomings.filter((shortc) => shortc.hasOwnProperty("priority") && shortc) ?? [];

        this.inspection.shortcomings = this.shortcomings.filter(Boolean);
        if (this.inspection.id === "" || this.inspection.id === undefined) this.inspection.id = UUIDGenerator();
        if (this.userBook.id) {
          if (this.shortcomings) {
            for (const shortcoming of this.shortcomings) {
              let resultArray: MultimediaDTO[] = [];
              if (shortcoming.temporalFiles && shortcoming.temporalFiles.length > 0) {
                for (const temporalFile of shortcoming.temporalFiles) {
                  const fetch = await this.uploadNewMedia(temporalFile);
                  !resultArray?.includes(fetch) && resultArray.push(fetch);
                  resultArray = resultArray.filter((value, index, self) => index === self.findIndex((t) => t.id === value.id));
                }
                shortcoming.files = [...(new Set([...(shortcoming.files ?? []), ...BookHelper.fromMultimediaToMediaAll(resultArray)]) as any)];
              }
            }
            this.inspection.shortcomings = this.shortcomings.filter(Boolean).filter((shortc, index, self) => {
              return self.findIndex((s) => s.name === shortc.name) === index;
            });
          }
          if (this.editingNormalizedDescription) {
            await this.bookRepository.addBuildingNormalizedDescription(this.userBook.id!, this.editingNormalizedDescription).then(res => {
              if (this.editingNormalizedDescription)
                this.userBook.buildingConstructionDescription = this.editingNormalizedDescription;
            });
          }
          if (this.inspection) {
            await this.bookRepository.addInspection(this.userBook.id! ?? "", this.inspection).then(res => {
              if (this.inspection) {
                if (this.userBook.inspections) {
                  const index = this.userBook?.inspections?.findIndex((i) => i.id === this.inspection?.id);
                  if (index !== -1) {
                    this.userBook.inspections[index] = this.inspection;
                  } else {
                    this.userBook.inspections = [...this.userBook.inspections, this.inspection];
                  }
                }
              }
            })
          }
          this.showInspectionModal = false;
          this.editingInspection = false;
          this.editingNormalizedDescription = null;
          this.shortcomings = [];
          this.inspection = null;
        }
      }
      this.loadingBook = false;
      return true;
    } catch (e) {
      console.error(e);
      this.loadingBook = false;
      return false;
    }
  };

  @action.bound
  uploadMedia = (file: File): Promise<MultimediaDTO> => this.uploadNewMedia(file);

  handleSaveImprovementFiles = async () => {
    try {
      this.loadingBook = true;
      if (this.improvementActionFiles) {
        const improvementActionMultimedias = await Promise.all(this.improvementActionFiles.map(this.uploadMedia));
        const mediaArray = BookHelper.fromMultimediaToMediaAll(improvementActionMultimedias);

        if (this.improvementAction) {
          this.improvementAction!.improvementActionMedias = [...(this.improvementAction?.improvementActionMedias ?? []), ...mediaArray];
          await this.bookRepository.addImprovementAction(this.userBook.id ?? "", this.improvementAction);
        }
        this.showFileuploader = false;
        this.showFileuploader = true;
        this.improvementActionFiles = [];
      }
      this.loadingBook = false;
    } catch (e) {
      this.loadingBook = false;
    }
  };

  saveDocuments = async () => {
    try {
      this.loadingBook = true;
      if (this.iteInspectionFiles) {
        const iteUpdatedFiles = await Promise.all(this.iteInspectionFiles.map(this.uploadMedia));
        const mediaArray = BookHelper.fromMultimediaToMediaAll(iteUpdatedFiles);
        mediaArray?.map((media) => {
          media.name = `ite-${media.name}`;
          return media;
        });
        await Promise.all(mediaArray.map(this.saveIteDocumentInBook));
        this.iteInspectionFiles = [];
      }
      if (this.ieeInspectionFiles) {
        const ieeUpdatedFiles = await Promise.all(this.ieeInspectionFiles.map(this.uploadMedia));
        const mediaArray = BookHelper.fromMultimediaToMediaAll(ieeUpdatedFiles);
        mediaArray?.map((media) => {
          media.name = `iee-${media.name}`;
          return media;
        });
        await Promise.all(mediaArray.map(this.saveIeeDocumentInBook));
        this.ieeInspectionFiles = [];
      }
      if (this.complementaryFiles) {
        const complementaryUpdatedFiles = await Promise.all(this.complementaryFiles.map(this.uploadMedia));
        const mediaArray = BookHelper.fromMultimediaToMediaAll(complementaryUpdatedFiles);
        await Promise.all(mediaArray.map(this.saveOtherDocumentInBook));
        this.complementaryFiles = [];
      }
      this.refreshFileUploader();
      this.loadingBook = false;
      return true;
    } catch (e) {
      this.loadingBook = false;
      return false;
    }
  };

  saveOtherDocumentInBook = async (media: MediaDTO) => {
    await this.bookRepository.addOtherDocumentation(this.userBook.id!, media).then(res => {
      this.userBook.otherDocuments = [...this.userBook.otherDocuments ?? [], media];
    })
  };
  saveIteDocumentInBook = async (media: MediaDTO) => {
    await this.bookRepository.addIteDocumentation(this.userBook.id!, media)
    this.userBook.iteDocuments = [...this.userBook.iteDocuments ?? [], media];
  };
  saveIeeDocumentInBook = async (media: MediaDTO) => {
    await this.bookRepository.addIeeDocumentation(this.userBook.id!, media);
    this.userBook.ieeDocuments = [...this.userBook.ieeDocuments ?? [], media];
  };

  @action
  saveAccesibility = async () => {
    try {
      this.loadingBook = true;
      const medias = await Promise.all(this.accessibilityEvaluationsFiles.map(this.createMediaWithoutApiAndReturnMedia));

      this.accessibilityEvaluations.media = [
        ...(this.accessibilityEvaluations.media ?? []),
        ...medias.map((media: MediaDTO) => ({ media: media.id } as AccessibilityEvaluationMediaDTO)),
      ];

      this.accessibilityEvaluations.media = this.accessibilityEvaluations.media.filter((media) => {
        return Object.getOwnPropertyNames(media).length;
      });


      await this.bookRepository.addAccesibilityEvaluation(this.userBook.id!, this.accessibilityEvaluations).then(res => {
        this.userBook.accessibilityEvaluations = [...this.userBook.accessibilityEvaluations ?? [], this.accessibilityEvaluations]
      })
      this.accessibilityEvaluations =
        this.userBook.accessibilityEvaluations![this.userBook.accessibilityEvaluations!.length - 1] ?? getAccesibilityEvaluationsMock();
      this.refreshFileUploader();
      this.loadingBook = false;
      return true;
    } catch (e) {
      this.loadingBook = false;
      return false;
    }
  };

  addAccesibilityEvaluation = async () => {
    try {
      this.loadingBook = true;
      await this.bookRepository.addAccesibilityEvaluation(this.userBook.id!, this.accessibilityEvaluations);
      this.loadingBook = false;
    } catch (e) {
      this.loadingBook = false;
    }
  };

  addEnergeticEfficiencyCertificate = async () => {
    try {
      this.loadingBook = true;
      const result = await this.bookRepository.addEnergyEfficiencyCertificate(this.userBook.id!, this.energeticEfficiencyCertificates).then(res => {
        this.userBook.energyEfficiencyCertificateDTO = [...this.userBook.energyEfficiencyCertificateDTO ?? [], this.energeticEfficiencyCertificates]
      })
      this.loadingBook = false;
      return true;
    } catch (e) {
      this.loadingBook = false;
      return false;
    }
  };

  addFinalEnergeticEfficiencyCertificate = async () => {
    try {
      this.loadingBook = true;
      await this.bookRepository.addEnergyEfficiencyCertificate(this.userBook.id!, this.finalEnergeticEfficiencyCertificates).then(res => {
        this.userBook.energyEfficiencyCertificateDTO = [...this.userBook.energyEfficiencyCertificateDTO ?? [], this.finalEnergeticEfficiencyCertificates]
      });
      this.loadingBook = false;
      return true;
    } catch (e) {
      this.loadingBook = false;
      return false;
    }
  };

  @action.bound async addLicense() {
    try {
      this.loadingBook = true;
      this.iteLicense.files = BookHelper.fromMultimediaToMediaAll(await Promise.all(this.iteLicenseDocuments.map(this.createMediaWithoutApi)));
      if (this.userBook && this.userBook.id) {
        this.iteLicense.files = this.iteLicense.files.filter((media) => {
          return Object.getOwnPropertyNames(media).length;
        });

        await this.bookRepository.addLicense(this.userBook!.id!, this.iteLicense).then(res => {
          this.userBook.licenses = [...this.userBook.licenses ?? [], this.iteLicense]
        })
      }
      this.iteLicenseDocuments = [];
      this.loadingBook = false;
      return true;
    } catch (e) {
      this.loadingBook = false;
      return false;
    }
  }

  @action.bound async addProceeding() {
    this.loadingBook = true;
    this.proceeding.files = BookHelper.fromMultimediaToMediaAll(await Promise.all(this.proceedingFiles.map(this.createMediaWithoutApi)));
    if (this.userBook && this.userBook.id) {
      this.userBook.proceedings = this.userBook.proceedings?.filter((media) => {
        return Object.getOwnPropertyNames(media).length;
      });
      await this.bookRepository.addProceedings(this.userBook!.id!, this.proceeding).then(res => {
        this.userBook.proceedings = [...this.userBook.proceedings ?? [], this.proceeding]
      })
    }
    this.proceedingFiles = [];
    this.loadingBook = false;
  }

  @action.bound async addComplementaryMedia() {
    this.loadingBook = true;
    const multimedias = await Promise.all(this.block1ComplementaryFiles.map(this.createMediaWithoutApi));
    let medias = BookHelper.fromMultimediaToMediaAll(multimedias);
    if (this.userBook && this.userBook.id) {
      medias = medias?.filter((media) => {
        return Object.getOwnPropertyNames(media).length;
      });
      await Promise.all(
        medias.map(
          async (media) => (await this.bookRepository.addOtherDocumentation(this.userBook!.id!, media).then(res => { this.userBook.otherDocuments = [...this.userBook.otherDocuments ?? [], media] }))
        )
      );
    }
    this.block1ComplementaryFiles = [];
    this.loadingBook = false;
  }

  translate(text: string) {
    this.parent.language = this.parent.configurationService.language;
    return (this.parent as RootViewModel).configurationService.translate(text);
  }

  @action changeTab = (selectedTab: string) => {
    return (this.activeTab = selectedTab);
  };

  setBook(book: BookDTO) {
    this.userBook = getBookMock(book);
  }

  handleEditBook = () => {
    if (this.parent.selectedBook) {
      this.parent.editingBook = true;
      // this.parent.navigate('report', {})
    }
  };

  @action
  handleDeleteFileBlock2Energy = async (id: string) => {
    await this.deleteFile(id);
    await this.addEnEfficiencyImprovement();
  };

  @action
  handleDeleteFileBlock2Security = async (id: string) => {
    await this.deleteFile(id);
    await this.addSecurityImprovement();
  };

  @action
  handleDeleteFileBlock2FireSafety = async (id: string) => {
    await this.deleteFile(id);
    await this.addFireSafetyImprovement();
  };

  @action
  handleDeleteFileBlock2Healthiness = async (id: string) => {
    await this.deleteFile(id);
    await this.addHealthinessImprovement();
  };

  @action
  handleDeleteFileBlock2Noise = async (id: string) => {
    await this.deleteFile(id);
    await this.addNoiseImprovement();
  };

  @action
  handleDeleteFileBlock2Other = async (id: string) => {
    await this.deleteFile(id);
    await this.addOtherImprovement();
  };

  async findNavigationChild(navigationName: string): Promise<any> {
    if (navigationName === undefined || navigationName === this.navigationName) {
      this.parent.tryActivateChild(this);
      this.child?.requestClose();
      return undefined;
    } else {
      this.loadingBook = true;
      const book = await this.bookRepository.getBookById({ id: navigationName, polygons: false, properties: false, usages: false })
      if (book) {
        this.parent.selectedBook = book;
        const buildingComposition = getBuildingCompositionDefaults(book?.buildingComposition);
        const buildingConstructionDescription = getBuildingConstructionDescriptionDefaults(book?.buildingConstructionDescription);
        const buildingLocationInfo = getBuildingLocationInfoDefaults(book?.buildingLocationInfo);
        this.userBook = getBookMock({
          ...book,
          buildingComposition,
          buildingConstructionDescription,
          buildingLocationInfo,
        });
        const usageRes = book.usagePlans?.find((i) => i.year === new Date().getFullYear());
        if (usageRes) this.useFunctionalDtoArray = usageRes;
        if (book.accessibilityEvaluations!!.length > 0)
          this.accessibilityEvaluations = getAccesibilityEvaluationsMock(book.accessibilityEvaluations!![book.accessibilityEvaluations!.length - 1]!!);
        this.urbanData = getUrbanDataMock(book!.urbanData!);

        if (book && book.energyEfficiencyCertificateDTO && book?.energyEfficiencyCertificateDTO?.length > 0) {
          const intialCertificate = book!.energyEfficiencyCertificateDTO.filter((a: any) => a.type === EnergyEfficiencyCertificateDTOTypeEnum.Initial);
          const finalCertificate = book!.energyEfficiencyCertificateDTO.filter((a: any) => a.type === EnergyEfficiencyCertificateDTOTypeEnum.Final);

          this.energeticEfficiencyCertificates = getEnergyEfficiencyCertificateMock(intialCertificate[intialCertificate.length - 1]!!);
          this.finalEnergeticEfficiencyCertificates = getEnergyEfficiencyCertificateMock(finalCertificate[finalCertificate.length - 1]!!);
        }
        this.buildingComposition = buildingComposition;
        this.improvementActions = book!.improvements!!;
        this.parent.editingBook = true;
        this.loadingBook = false;
        return this.detailFactory(this.bookRepository, book!!);
      } else {
        return undefined;
      }
    }
  }

  @action async createImprovement(id: string, improvement: ImprovementActionDTO) {
    try {
      await this.bookRepository.addImprovementAction(id, improvement).then(res => {
        this.userBook.improvements = [...this.improvementAction?.improvements ?? [], improvement]
      });
      // this.improvementAction = auxBook.improvements!![auxBook!!.improvements!!.length - 1];
      // this.userBook.improvements = auxBook.improvements;
      return true;
    } catch (e) {
      return false;
    }
  }

  @action
  downloadFile = async (url: string) => {
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.setAttribute("download", "file");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  @action.bound deleteFile = async (mediaId: string): Promise<boolean | null> => {
    try {
      this.loadingBook = true;
      const done = await this.bookRepository.deleteMediaByIdFromBook(this.userBook.id!, mediaId);
      this.loadingBook = false;
      return done;
    } catch (e: any) {
      this.loadingBook = false;
      console.log(e.message);
      return Promise.resolve(null);
    }
  };

  @action.bound async addImprovementFunc() {
    this.hideInspectionSave = false;
    this.setImprovementAction({
      ...this.improvementAction,
      securityImprovement: this.securityImprovement,
      fireSafetyImprovement: this.fireSafetyImprovementDTO,
      healthinessImprovement: this.healthinessImprovementDTO,
      //improvements: this.improvementsDTO,
      noiseImprovement: getNoiseImprovementDTOMock(this.noiseImprovementDTO),
      otherImprovement: this.otherImprovementDTO,
      enEfficiencyImprovement: this.energeticEfficiencyImprovementDTO,
    });
    if (
      !this.userBook.improvements?.find((item) => {
        return item.id === this.improvementAction?.id!!;
      })
    ) {
      this.loadingBook = true;
      await this.createImprovement(this.userBook.id!!, this.improvementAction!!);
      this.loadingBook = false;
    }
    const result = await this.addImprovements();
    if (result) this.addImprovement = false;
    this.improvementDTO = getImprovementDTOMock();
    return result;
  }

  @action.bound async addEnergyImprovement(
    energyData: CompleteEnergyEfficiencyImprovementDataDTO,
    acsEnergyConsumptionReduction: any,
    acsEnergyConsumptionReductionPercent: any,
    initialGlobalEnergyDemand: any,
    lightningEnergyConsumptionReduction: any,
    coolingEnergyConsumptionReduction: any,
    coolingEnergyConsumptionReductionPercent: any,
    finalGlobalEnergyDemand: any,
    heatingEnergyConsumptionReduction: any,
    heatingEnergyConsumptionPercentReduction: any,
    initialGlobalEnergyDemandReduction: any,
    initialGlobalEnergyDemandReductionPercent: any,
    initialNonRenewablePrimaryEnergyConsumptionReduction: any,
    initialNonRenewablePrimaryEnergyConsumptionReductionPercent: any,
    investment: any,
    lightningEnergyConsumptionReductionPercent: any
  ) {
    if (!this.userBook.improvements?.find((item) => item.id === this.improvementAction?.id!!)) {
      this.loadingBook = true;

      const actionResult = await this.createImprovement(this.userBook.id!!, this.improvementAction!!);

      if (!actionResult) {
        this.loadingBook = false;
        this.openEnergeticImprovementForm = false;
        this.addImprovement = false;
        return false;
      }
    }
    let improv = fromCompleteEnergyEfficiencyImprovementDataDTOToEnergyEfficiencyImprovementDataDTO(energyData);
    improv = {
      ...improv,
      id: this.selectedImprovementDTO?.id ?? UUIDGenerator(),
      energyEfficiencyMeasureData: {
        ...improv.energyEfficiencyMeasureData,
        acsEnergyDemandReduction: acsEnergyConsumptionReduction,
        acsEnergyDemandReductionPercent: acsEnergyConsumptionReductionPercent,
        initialGlobalEnergyDemand,
        lightningEnergyDemandReduction: lightningEnergyConsumptionReduction,
        lightningEnergyDemandReductionPercent: lightningEnergyConsumptionReductionPercent,
        coolingEnergyDemandReduction: coolingEnergyConsumptionReduction,
        coolingEnergyDemandReductionPercent: coolingEnergyConsumptionReductionPercent,
        finalGlobalEnergyDemand,
        heatingEnergyDemandReduction: heatingEnergyConsumptionReduction,
        heatingEnergyDemandReductionPercent: heatingEnergyConsumptionPercentReduction,
        globalEnergyDemandReduction: initialGlobalEnergyDemandReduction,
        globalEnergyDemandReductionPercent: initialGlobalEnergyDemandReductionPercent,
        initialNonRenewablePrimaryEnergyConsumptionReduction,
        initialNonRenewablePrimaryEnergyConsumptionReductionPercent,
        investment,
      },
    };
    return this.improvementActionsRepository
      .addImprovementToImprovementAction({
        id: this.improvementAction!!.id!!,
        improvementDTO: improv,
      })
      .then((value: ImprovementActionDTO) => {
        this.openEnergeticImprovementForm = false;
        this.addImprovement = false;
        this.improvementsDTO = value.improvements ?? [];
        this.improvementAction = value;
        return true;
      })
      .catch((error: any) => false)
      .finally(() => {
        this.loadingBook = false;
        this.addImprovement = false;
      });
  }

  addSecurityImprovement = async () => {
    try {
      const requestParameters: SetSecurityImprovementRequest = {
        id: this.improvementAction!!.id!!,
        securityImprovementDTO: this.securityImprovement,
      };
      this.loadingBook = true;
      this.improvementAction = await this.improvementActionsRepository.setSecurityImprovement(requestParameters);
      this.loadingBook = false;
      return true;
    } catch (e) {
      this.loadingBook = false;
      return false;
    }
  };

  addEnEfficiencyImprovement = async () => {
    try {
      const requestParameters: SetSavingEnergyImprovementRequest = {
        id: this.improvementAction!!.id!!,
        energyEfficiencyImprovementDTO: this.energeticEfficiencyImprovementDTO,
      };
      this.loadingBook = true;
      this.improvementAction = await this.improvementActionsRepository.setSavingEnergyImprovement(requestParameters);
      this.loadingBook = false;
      return true;
    } catch (e) {
      this.loadingBook = false;
      return false;
    }
  };

  addFireSafetyImprovement = async () => {
    try {
      const requestParameters: SetFireSafetyImprovementRequest = {
        id: this.improvementAction!!.id!!,
        fireSafetyImprovementDTO: this.fireSafetyImprovementDTO,
      };
      this.loadingBook = true;
      this.improvementAction = await this.improvementActionsRepository.setFireSafetyImprovement(requestParameters);
      this.loadingBook = false;
      return true;
    } catch (e) {
      this.loadingBook = false;
      return false;
    }
  };

  addHealthinessImprovement = async () => {
    try {
      const requestParameters: SetHealthinessImprovementRequest = {
        id: this.improvementAction!!.id!!,
        healthinessImprovementDTO: this.healthinessImprovementDTO,
      };
      this.loadingBook = true;
      this.improvementAction = await this.improvementActionsRepository.setHealthinessImprovement(requestParameters);
      this.loadingBook = false;
      return true;
    } catch (e) {
      this.loadingBook = false;
      return false;
    }
  };

  addNoiseImprovement = async () => {
    try {
      const requestParameters: SetNoisesImprovementRequest = {
        id: this.improvementAction!!.id!!,
        noiseImprovementDTO: this.noiseImprovementDTO,
      };
      this.loadingBook = true;
      this.improvementAction = await this.improvementActionsRepository.setNoisesImprovement(requestParameters);
      this.loadingBook = false;
      return true;
    } catch (e) {
      this.loadingBook = false;
      return false;
    }
  };

  addOtherImprovement = async () => {
    try {
      const requestParameters: SetOtherImprovementRequest = {
        id: this.improvementAction!!.id!!,
        otherImprovementDTO: this.otherImprovementDTO,
      };
      this.loadingBook = true;
      this.improvementAction = await this.improvementActionsRepository.setOtherImprovement(requestParameters);
      this.loadingBook = false;
      return true;
    } catch (e) {
      this.loadingBook = false;
      return false;
    }
  };

  addDeficienciesDetectedImprovement = async () => {
    try {
      const requestParameters: AddImprovementToImprovementActionRequest = {
        id: this.improvementDTO!!.id!!,
        improvementDTO: this.improvementDTO,
      };
      this.loadingBook = true;
      this.improvementAction = await this.improvementActionsRepository.addImprovementToImprovementAction(requestParameters);
      this.loadingBook = false;
      return true;
    } catch (e) {
      this.loadingBook = false;
      return false;
    }
  };

  addMaintenance = async (id: string) => {
    try {
      this.loadingBook = true;
      await this.bookRepository.setMaintenance(id, this.maintenancePlanDtoArray).then((res) => {
        this.userBook.maintenancePlanActions = [...this.userBook.maintenancePlanActions ?? [], ...this.maintenancePlanDtoArray.actions ?? []]
      })
      this.loadingBook = false;
      return true;
    } catch (e) {
      this.loadingBook = false;
      return false;
    }
  };

  addUsage = async (id: string) => {
    this.loadingBook = true;
    try {
      await this.bookRepository.addUsagePlan(id, this.useFunctionalDtoArray).then((res) => {
        this.userBook.usagePlans = [...this.userBook.usagePlans ?? [], this.useFunctionalDtoArray]
      })
      this.useFunctionalDtoArray = {
        ...this,
        year: this.useFunctionalDtoArray.year,
        privateAndCommonSpaces: this.useFunctionalDtoArray.privateAndCommonSpaces,
      };
      this.loadingBook = false;
      return true;
    } catch (e) {
      this.loadingBook = false;
      return false;
    }
  };

  addImprovements = async () => {
    const requestParameters: AddImprovementToImprovementActionRequest = {
      id: this.improvementAction!!.id!!,
      improvementDTO: this.improvementDTO,
    };

    if (!this.improvementDTO.id) {
      if (
        this.improvementDTO.type === undefined ||
        this.improvementDTO.difficulty === undefined ||
        this.improvementDTO.estimatedCost === undefined ||
        this.improvementDTO.name === undefined ||
        this.improvementDTO.priority === undefined ||
        this.improvementDTO.saving === undefined
      )
        return false;

      //fill this.improvementDTO
      this.improvementDTO.status = ImprovementDTOStatusEnum.Proposed;
      this.improvementDTO.timestampOfExecution = new Date().valueOf();
      this.improvementDTO.id = UUIDGenerator();
      this.improvementDTO.order = -1;
    }

    this.loadingBook = true;
    return this.improvementActionsRepository

      .addImprovementToImprovementAction(requestParameters)
      .then((res: ImprovementActionDTO) => {
        this.improvementAction = res;
        this.improvementsDTO = res.improvements ?? [];
        return true;
      })

      .catch((error: any) => false)
      .finally(() => (this.loadingBook = false));
  };

  deleteImprovementFromImprovementActionDTO = async (improvementId: string): Promise<boolean> => {
    this.loadingBook = true;
    return this.improvementActionsRepository
      .deleteImprovementFromImprovementAction({
        id: this.improvementAction?.id ?? "",
        improvementId: improvementId,
      })
      .then((res: ImprovementActionDTO) => {
        this.improvementAction = res;
        this.improvementsDTO = res.improvements ?? [];
        return true;
      })
      .catch((error: any) => false)
      .finally(() => (this.loadingBook = false));
  };

  checkExistingUser = async (idNumber?: string, username?: string): Promise<AgentDTO> => {
    const reqParams: SearchAgentRequest = {};

    if (idNumber?.trim() === "" && username?.trim() !== "") {
      reqParams.username = username;
    } else {
      reqParams.idNumber = idNumber;
    }

    return this.userManagementRepository.searchAgent(reqParams);
  };

  getAgentById = async (credentialsId: string): Promise<AgentDTO> => {
    return await this.userManagementRepository.searchAgentByCredentialsId({ credentialsId });
  };

  @action.bound setLoadingBook = (loading: boolean) => {
    this.loadingBook = loading;
  };

  setImprovementAction = (improvementAction: ImprovementActionDTO) => {
    this.improvementAction = { ...improvementAction };
    this.securityImprovement = improvementAction.securityImprovement!!;
    this.fireSafetyImprovementDTO = improvementAction.fireSafetyImprovement!!;
    this.healthinessImprovementDTO = improvementAction.healthinessImprovement!!;
    this.energeticEfficiencyImprovementDTO = improvementAction.enEfficiencyImprovement!!;
    this.noiseImprovementDTO = getNoiseImprovementDTOMock(improvementAction.noiseImprovement!!);
    this.otherImprovementDTO = improvementAction.otherImprovement!!;
    this.improvementsDTO = improvementAction.improvements ?? [];
  };

  checkPayment = async () => {
    try {
      if (this.parent.technicianData?.collegiated?.length) {
        const collegeId = this.parent.technicianData.collegiated[0].college!.id!;
        this.loadingBook = true;
        await this.bookRepository
          .initPayment({ collegeId })
          .then((res) => {
            if (res !== null) {
              this.paymentButton = true;
              this.cecaSignatureConfiguration = res;
            } else {
              this.paymentButton = false;
              this.cecaSignatureConfiguration = null;
            }
            this.loadingBook = false;
          })
          .catch((err) => {
            this.loadingBook = false;
            this.cecaSignatureConfiguration = null;
            this.paymentButton = false;
          });
      }
    } catch (e: any) {
      this.loadingBook = false;
    }
    this.loadingBook = false;
  };

  updateFinalImprovements = async (improvements: ImprovementDTO[]) => {
    return this.improvementActionsRepository.updateMultipleImprovements({
      id: this.improvementAction!.id!,
      improvementContainerDTO: {
        improvementsDTO: improvements,
      },
    });
  };

  @action async editTechnicianParticipation() {
    try {
      this.loadingBook = true;
      await this.bookRepository.addTechnician(this.userBook.id!!, {
        id: this.selectedTechnicianParticipation!!.id,
        participation: this.selectedTechnicianParticipation!!.participation,
        college: this.selectedTechnicianParticipation!!.college,
      }).then(res => {
        this.userBook.technicians =
          this.userBook.technicians?.map(t => t.id === this.selectedTechnicianParticipation!!.id ? {
            ...this.selectedTechnicianParticipation,
          } : t) ?? [];
      });
      this.loadingBook = false;
      return true;
    } catch {
      this.loadingBook = false;
      return false;
    }
  }

  @action async getUsagesByBookId(req: GetUsagesByBookIdRequest) {
    return await this.bookRepository.getUsagesByBookId(req)
  }

  @action async getUsagePage(req: GetUsagePageByBookIdRequest) {
    return await this.bookRepository.getUsagePageByBookId(req)
  }

  @action async deleteTechnicianParticipation() {
    try {
      this.loadingBook = true;
      await this.bookRepository.deleteTechnician(this.userBook.id!!, this.selectedTechnicianParticipation!!.id!!);
      this.userBook.technicians = this.userBook.technicians?.filter((t) => t.id !== this.selectedTechnicianParticipation!!.id);
      this.loadingBook = false;
      return true;
    } catch {
      this.loadingBook = false;
      return false;
    }
  }

  @action async inviteTechnician() {
    try {
      //Invitar técnico
      this.loadingBook = true;
      await this.userManagementRepository.technicianInvitation({
        technicianInviteDTO: {
          buildingBookId: this.userBook.id,
          invitedTechnicianEmail: this.invitedTechnicianEmail,
          invitedTechnicianName: this.invitedTechnicianName,
        },
      });
      this.loadingBook = false;
      return true;
    } catch (error: any) {
      console.log(error);
      this.loadingBook = false;
      return false;
    }
  }

  @action setBuildingOccupancyType = (buildingType: BuildingLocationInfoDTOBuildingOccupancyTypeEnum) => {
    this.locationInfo.buildingOccupancyType = buildingType;
    this.userBook.buildingLocationInfo!!.buildingOccupancyType = buildingType;
  };
  @action setSharesCommonElementsWithContiguousBuildings = (shares: string) => {
    this.locationInfo.sharesCommonElementsWithContiguousBuildings = shares;
    this.userBook.buildingLocationInfo!!.sharesCommonElementsWithContiguousBuildings = shares;
  };

  @action setExcludeAccesibilityFromPDF = (bo: boolean): void => {
    this.accessibilityEvaluations.excludeDataToFill = bo;
  };
  @action.bound refreshFileUploader = async () => {
    this.showFileuploader = false;
    await this.sleep(1);
    this.showFileuploader = true;
  };

  sleep = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  @action editPhase(phase: PhaseDTO) {
    if (this.improvementAction?.phases) {
      const a = [...this.improvementAction.phases];
      a.splice(
        this.improvementAction?.phases?.findIndex((p) => p.id === phase.id),
        1,
        phase
      );
      this.improvementAction.phases = a;
    }
  }

  @action async savePhase() {
    if (!this.improvementAction) return;
    if (!this.improvementAction.phases) return;
    for await (const phase of this.improvementAction!.phases!) {
      await this.improvementActionsRepository
        .addPhaseToImprovementAction({ id: this.improvementAction.id!, phaseDTO: phase })
        .catch((err) => console.log(err));
    }
  }

  @action transformIntoFileAndReduceWeight = async (multimedias: MediaPrintedDTO[]): Promise<ImageMedia[]> => {
    const files = [];
    for (const multimedia of multimedias) {
      try {
        const result = await fetch(multimedia?.url ?? "")
          .then((res) => res.blob())
          .catch((e) => new File([], "noname.txt"))
          .then((blob) => {
            return { backupUrl: multimedia.url ?? "no_url", file: new File([blob], multimedia?.name! ?? "noname.txt") };
          });
        files.push(result);
      } catch (error) {
        console.log(error);
      }
    }

    try {
      const compressedFilesPromises = files
        .filter(
          (obj: { backupUrl: string; file: File }) => obj.file.name.includes(".png") || obj.file.name.includes(".jpeg") || obj.file.name.includes(".jpg")
        )
        .map(async (obj) => {
          return new Promise<{
            file: File;
            backupUrl: string;
          }>((resolve) => {
            new Compressor(new Blob([obj.file], { type: "image/jpeg" }), {
              quality: 0.8,
              error: (error: any) => { },
              success(result: File) {
                resolve({ backupUrl: obj.backupUrl, file: new File([result], obj.file?.name!) });
              },
            });
          });
        });
      const compressedFiles = await Promise.all(compressedFilesPromises);
      const rest = files.filter((file) => !compressedFiles.some((compressedFile) => compressedFile.file.name === file.file.name));

      const completeFiles = [];
      for (const multimediaCompressed of [...rest, ...compressedFiles]) {
        try {
          const result = await this.getOrientationAndScale(multimediaCompressed);
          completeFiles.push(result);
        } catch (error) { }
      }
      // const completeFiles = await Promise.all([...rest, ...compressedFiles].map(this.getOrientationAndScale));
      return completeFiles;
    } catch (e) {
      console.log("errore", e);
      return [];
    }
  };

  getOrientationAndScale = async (i: { backupUrl: string; file: File }) => {
    try {
      const orientationInfo = await exif.getOrientation(i.file);
      let photoOrientation = orientationInfo?.rotation ?? 0;
      const scale = photoOrientation !== 0 && photoOrientation !== 180 ? "scale(0.7, 0.7)" : "";
      const transform = `rotate(${photoOrientation}deg) ${scale}`;

      return {
        ...i,
        url: URL.createObjectURL(i.file) as any,
        transform: transform,
      } as ImageMedia;
    } catch (e) {
      return {
        url: process.env.REACT_APP_PROXY_URL,
        transform: "rotate(0deg) scale:(0.7, 0.7)",
      } as ImageMedia;
    }
  };

  @action reduceFileWeight = async (file: File): Promise<File> => {
    if (!file?.name?.toLowerCase()?.endsWith(".png") && !file?.name?.toLowerCase()?.endsWith(".jpeg") && !file?.name?.toLowerCase()?.endsWith(".jpg"))
      return file;
    if (file?.name === undefined) return file;
    try {
      if ((file?.size ?? 0) > 1500000 && (file?.size ?? 0) < 3000000) {
        const compressedFilesPromise = new Promise<File>((resolve) => {
          new Compressor(new Blob([file], { type: "image/jpeg" }), {
            quality: 0.8,
            error: (error: any) => { },
            success(result: File) {
              resolve(new File([result], file.name));
            },
          });
        });
        return await compressedFilesPromise;
      }
      if ((file?.size ?? 0) > 3000000) {
        const compressedFilesPromise = new Promise<File>((resolve) => {
          new Compressor(new Blob([file], { type: "image/jpeg" }), {
            quality: 0.6,
            error: (error: any) => { },
            success(result: File) {
              resolve(new File([result], file.name));
            },
          });
        });
        return await compressedFilesPromise;
      } else {
        return file;
      }
    } catch (e) {
      console.log(e);
      return file;
    }
  };

  @action async deleteProceeding(proceedingId: string): Promise<boolean | undefined> {
    if (this.userBook.id) return (await this.bookRepository.deleteProceedingById(this.userBook.id, proceedingId))
    else throw new Error("No book id");
  }

  @action async deleteLicense(licenseId: string): Promise<boolean | undefined> {
    if (this.userBook.id) return (await this.bookRepository.deleteLicenseById(this.userBook.id, licenseId));
    else throw new Error("No book id");
  }
}
