/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from "@movicoders/movicoders-components";
import { Grid } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { CadastralBasicPropertyDTO } from "../../../../../../../clients";
import BackDropLoading from "../../../../../../../components/backdrop-loading";
import TextAreaGrid from "../../../../../../../components/text-area";
import TextFieldGrid from "../../../../../../../components/text-fields/textfield-grid";
import TransferList from "../../../../../../../components/transfer-list/transfer-list";
import TypographyTitle from "../../../../../../../components/typography-title";
import { Colors } from "../../../../../../../constants";
import ProfileViewModel from "../../../../../../../viewmodels/profile/profile-view-model";
import ReportViewModel from "../../../../../../../viewmodels/report/report-view-model";
import { useCommunitySelector } from "../../../../../../profile/profile-components/form/form-components/useCommunitySelector";
import { ReportStyle } from "../../../../../report-style";
import { useCheckUsages } from "../../hooks/useCheckUsages";

export const ReferenceCadastralGenerator: React.FC<{ vm: ReportViewModel }> = observer(({ vm }) => {
  const classes = ReportStyle();
  const [selectedData, setSelectedData] = useState<Array<CadastralBasicPropertyDTO>>([]);
  const [searchReference, setSearchReference] = useState("");
  const [referenceDescription, setReferenceDescription] = useState(vm.userBook.buildingLocationInfo!.referenceDescription ?? "");

  useEffect(() => {
    if (vm.userBook.buildingLocationInfo!.referenceDescription && !vm.parent.isManual)
      setReferenceDescription(vm.userBook.buildingLocationInfo!.referenceDescription);
  }, [vm.userBook.buildingLocationInfo!.referenceDescription]);
  const {
    view: communitySelector,
    isManual,
    isCommunitySelected,
  } = useCommunitySelector(vm.parent.children.find((r) => r.navigationName === "profile") as ProfileViewModel);
  const { enqueueSnackbar } = useSnackbar();
  const { checkUsages } = useCheckUsages(vm);

  useEffect(() => {
    if (vm.parent.editingBook && vm.parent.selectedBook) {
      setSearchReference(vm.parent.selectedBook.code?.split(".")[3] ?? "");
    }
  }, []);

  useEffect(() => {
    vm.parent.selectedBook && setSearchReference(vm.parent!.selectedBook!.code?.split(".")[3] ?? "");
  }, [vm.parent.settingBookFromVM]);

  useEffect(() => {
    vm.parent.isManual = isManual;
  }, [isManual]);

  useEffect(() => {
    if (vm.parent.isManual) vm.locationInfo.referenceDescription = referenceDescription;
  }, [referenceDescription]);

  useEffect(() => {
    if (vm.errorBookCreation === true) {
      handleOpenSnackBar(vm.translate("report.block0.init.error"), "error");
      vm.errorBookCreation = false;
    }
  }, [vm.errorBookCreation]);

  useEffect(() => {
    if (vm.parent.editingBook === false) {
      vm.userBook.id = "";
    }
  }, [selectedData]);

  const transferListSort = (data: CadastralBasicPropertyDTO[]) => {
    return data.sort((a: CadastralBasicPropertyDTO, b: CadastralBasicPropertyDTO) =>
      (a.id?.toLowerCase() ?? "") > (b.id?.toLowerCase() ?? "") ? 1 : -1 || 0
    );
  };

  const handleError = async (error: any) => {
    const toStream = new Response(error.body);
    const errorResponse = (await toStream.json()) as any;
    return errorResponse;
  };

  const handleOpenSnackBar = (snackKey: string, variants: undefined | "default" | "error" | "success" | "warning" | "info") => {
    enqueueSnackbar(snackKey, { variant: variants, preventDuplicate: true, key: snackKey, autoHideDuration: 3000 });
  };

  const initBook = async () => {
    let response: any;
    try {
      vm.loadingBook = true;
      await vm.getBuildingMoreCollegiateds(vm.buildingIds ?? [], vm.parcel?.media ?? []);
      setSelectedData([]);
      checkUsages();
    } catch (error: any) {
      response = await handleError(error);
      vm.loadingBook = false;
    } finally {
      if (vm.userBook.id !== "") {
        setSelectedData([]);
        response
          ? handleOpenSnackBar(vm.translate("report.block0.init.error"), "error")
          : handleOpenSnackBar(vm.translate("report.block0.init.success"), "success");
        vm.loadingBook = true;
        vm.showLoading = false;
      }
    }

    vm.loadingBook = false;
    vm.parcel = undefined;
    vm.showLoading = false;
  };

  const getParcel = async (reference: string) => {
    setSearchReference(reference);
    if (vm.parent.isManual) {
      if (vm.locationInfo.address) {
        const newStr = reference?.replace(/\s+/g, "");

        vm.manualCadastralReference = newStr;
        vm.locationInfo.address.cadastralParcelReference = newStr;
      }
    } else {
      if (reference.length === 20) {
        await vm.getParcels(reference.replaceAll(" ", "").substring(0, 14)).then((res) => {
          res
            ? handleOpenSnackBar(vm.translate("report.block0.get.parcel.success"), "success")
            : handleOpenSnackBar(vm.translate("report.block0.get.parcel.error"), "error");
        });
      } else if (reference.length === 14 && !vm.parent.editingBook) {
        await vm.getParcels(reference.replaceAll(" ", "")).then((res) => {
          res
            ? handleOpenSnackBar(vm.translate("report.block0.get.parcel.success"), "success")
            : handleOpenSnackBar(vm.translate("report.block0.get.parcel.error"), "error");
        });
      }
    }
  };

  return (
    <React.Fragment>
      <BackDropLoading showLoading={vm.showLoading} />
      <Grid item xs={12} md={12} className={classes.report_displayGridTitle}>
        {!vm.parent.editingBook ? (
          <React.Fragment>
            <TypographyTitle id="cadastralReferenceTitle" title={vm.translate("report.block0.cadastral.reference")} />
            <Grid display="inline-flex">
              {communitySelector}
              <TextFieldGrid
                id="cadastralReferenceParcel"
                disabled={vm.parent.editingBook || !isCommunitySelected}
                value={searchReference}
                title={vm.translate("report.block0.parcel")}
                onChange={(event: any) => getParcel(event.target.value)}
                gridTextField={{ xs: 6, md: 6 }}
              />
            </Grid>
            {vm.parent.isManual ? (
              <TextAreaGrid
                id="cadastralReferenceDescription"
                rows={1}
                label={vm.translate("report.block0.observations")}
                onChange={(evt: any) => {
                  setReferenceDescription(evt.target.value);
                  vm.manualCadastralReferenceDescription = evt.target.value;
                }}
                value={referenceDescription}
                style={{ marginRight: 5 }}
                gridTextField={{ xs: 12, md: 12 }}
                charLimit={4000}
              />
            ) : null}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <TypographyTitle id="cadastralReferenceTitle" title={vm.translate("report.block0.cadastral.reference")} />
            <TextFieldGrid
              id="cadastralReferenceParcel"
              disabled={vm.parent.editingBook}
              value={vm.userBook.buildingLocationInfo?.address?.cadastralParcelReference}
              title={vm.translate("report.block0.parcel")}
              onChange={(reference: any) => getParcel(reference.target.value)}
              gridTextField={{ xs: 6, md: 6 }}
            />
            <TextAreaGrid
              id="cadastralReferenceDescription"
              rows={1}
              label={vm.translate("report.block0.observations")}
              onChange={(evt: any) => {
                setReferenceDescription(evt.target.value);
                vm.userBook.buildingLocationInfo!.referenceDescription = evt.target.value;
                if (vm.locationInfo) vm.locationInfo.referenceDescription = evt.target.value;
              }}
              value={referenceDescription}
              style={{ marginRight: 5 }}
              gridTextField={{ xs: 12, md: 12 }}
              charLimit={4000}
            />
          </React.Fragment>
        )}
        {vm.parcel && !vm.isOpenEditUsages && (
          <TransferList
            initialData={vm.parcel?.properties ?? []}
            customToolbarPosition="top"
            textGetter={(value: CadastralBasicPropertyDTO) =>
              `${vm.translate("report.block0.buildingIdentification.referenceCadastral.CL")} ${value.name}, ${vm.translate(
                "report.block0.buildingIdentification.referenceCadastral.doorway"
              )} ${value.portalNumber}${value.portalNumberLetter ?? ""}, ${value.block ?? ""} ${vm.translate(
                "report.block0.buildingIdentification.referenceCadastral.floor"
              )} ${value.floor}, ${vm.translate("report.block0.buildingIdentification.referenceCadastral.door")} ${value.door}`
            }
            textGetter2={(value: CadastralBasicPropertyDTO) =>
              `${vm.translate("report.block0.buildingIdentification.referenceCadastral.REF")} ${value.pc1}${value.pc2}${value.car}${value.cc1}${value.cc2}`
            }
            selectedData={selectedData}
            selectedDataSetter={(properties) => {
              vm.buildingIds = properties.map((item: any) => item.id);
              setSelectedData(properties);
            }}
            leftPanelTitle={vm.translate("report.block0.properties")}
            leftPanelSubtitle={""}
            rightPanelTitle={vm.translate("report.block0.properties.selected")}
            rightPanelSubtitle={""}
            titleColor={Colors.textPrimaryHeaders}
            searchPlaceholder={vm.translate("report.block0.properties.searchBox")}
            listSortingFunction={transferListSort}
            customToolbar={
              <Grid container justifyContent="flex-end" style={{ marginLeft: 5, marginTop: 10 }}>
                <Button
                  id="cadastralReferenceGenerate"
                  text={vm.translate("generate")}
                  onClick={() => {
                    initBook();
                  }}
                  disabled={selectedData.length === 0}
                  textColor={Colors.white}
                  backgroundColor={Colors.buttonPrimary}
                  hoverColor={Colors.buttonPrimary}
                />
              </Grid>
            }
          />
        )}
      </Grid>
    </React.Fragment>
  );
});
